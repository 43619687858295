<template>
  <div>
    <div class="d-flex align-items-center"></div>
    <b-tabs
      pills
      active-nav-item-class="bg-light-primary border-0 shadow-none cursor-pointer"
      v-model="tab_index"
      lazy
      @activate-tab="onTabChanged"
    >
      <b-tab lazy active>
        <template #title>
          <div
            @contextmenu.prevent="$refs.menu.open"
            class="d-flex align-items-center"
          >
            <i class="bi bi-person-vcard font-medium-1"></i>
            <span class="ml-50 font-weight-bold">CRM</span>
          </div>
        </template>

        <b-card
          class="mb-0"
          no-body
          :style="{ height: '53rem', overflow: 'auto' }"
        >
          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-1">
              <b-dropdown
                boundary="viewport"
                size="sm"
                v-model="selected_call_list"
                :text="selected_call_list.display_name"
                variant="flat-secondary"
                class="bg-light-secondary mr-2"
              >
                <b-dropdown-item
                  v-for="(item, i) in call_list_items"
                  :key="i"
                  @click="
                    () => {
                      selected_call_list = item;
                      getCustomers();
                    }
                  "
                >
                  {{ item.display_name }}
                </b-dropdown-item>
              </b-dropdown>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  @keydown.enter="getCustomers()"
                  v-model="customerSearchTerm"
                  :placeholder="lang('t_searchPlaceHolder')"
                ></b-form-input>
              </b-input-group>

              <b-button
                v-if="crm_permissions.includes('add_customer')"
                @click="openAddCustomer"
                v-b-tooltip.hover.top="lang('t_addCustomer')"
                v-b-tooltip.hover.v-secondary
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <i class="bi bi-plus font-medium-2"></i>
              </b-button>
              <!-- <b-button
                v-if="crm_permissions.includes('fields')"
                v-b-tooltip.hover.top="lang('t_titles')"
                v-b-tooltip.hover.v-secondary
                @click="modal_column_settings = true"
                size="sm"
                class="px-50 py-50"
                variant="flat-secondary"
              >
                <b-icon icon="eye"></b-icon>
              </b-button> -->

              <b-dropdown
                v-if="
                  crm_permissions.includes('fields') && customer_list.length > 0
                "
                @hidden="SaveColumns"
                variant="flat-secondary"
                no-caret
                :right="$store.state.appConfig.isRTL"
                toggle-class="px-50 py-50"
                v-b-tooltip.hover.top="lang('t_titles')"
                v-b-tooltip.hover.v-secondary
                menu-class="dCustomClass"
                boundary="viewport"
                dropleft
              >
                <template #button-content>
                  <b-icon icon="layout-three-columns"></b-icon>
                </template>

                <b-dropdown-form>
                  <div
                    class="d-flex align-items-center"
                    :key="i"
                    v-for="(col, i) in headers"
                  >
                    <b-button
                      class="mr-50 px-50 py-50"
                      @click="setTitle(col)"
                      size="sm"
                      variant="flat-secondary"
                    >
                      <i
                        :class="[
                          col.title
                            ? 'text-primary bi bi-star-fill'
                            : 'text-primary bi bi-star',
                        ]"
                      ></i>
                    </b-button>
                    <b-button
                      class="mr-50 px-50 py-50"
                      @click="setSubTitle(col)"
                      size="sm"
                      variant="flat-secondary"
                    >
                      <i
                        :class="[
                          col.sub_title
                            ? 'text-primary bi bi-circle-fill'
                            : 'text-primary bi bi-circle',
                        ]"
                      ></i>
                    </b-button>

                    <b-form-checkbox
                      class="mr-1 ml-50"
                      v-model="col.visible"
                    ></b-form-checkbox>

                    <span class="mr-1"> {{ col.label }}</span>
                  </div>
                </b-dropdown-form>
              </b-dropdown>
              <b-button
                v-if="
                  crm_permissions.includes('filter') && customer_list.length > 0
                "
                @click="modal_filters = true"
                v-b-tooltip.hover.top="lang('t_filters')"
                v-b-tooltip.hover.v-secondary
                size="sm"
                class="px-50 py-50"
                variant="flat-secondary"
              >
                <b-icon icon="funnel"></b-icon>
              </b-button>

              <b-dropdown
                variant="flat-secondary"
                v-b-tooltip.hover.top="lang('t_export')"
                v-b-tooltip.hover.v-secondary
                no-caret
                :right="$store.state.appConfig.isRTL"
                toggle-class="px-50 py-50"
                v-if="
                  (crm_permissions.includes('management') ||
                    crm_permissions.includes('excel_customer') ||
                    crm_permissions.includes('excel_customer_by_phone')) &&
                  customer_list.length > 0
                "
                boundary="viewport"
                dropleft
              >
                <template #button-content>
                  <i class="bi bi-download"></i>
                </template>

                <b-dropdown-item
                  v-if="
                    customer_list.length > 0 &&
                    crm_permissions.includes('excel_customer')
                  "
                  @click="CustomerListExport"
                  >{{ lang("t_customerList") }}</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="
                    customer_list.length > 0 &&
                    crm_permissions.includes('excel_customer_by_phone')
                  "
                  @click="CustomerListWithPhoneDetailsExport"
                  >{{ lang("t_customerListByPhone") }}</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="
                    customer_list.length > 0 &&
                    crm_permissions.includes('excel_customer_by_product')
                  "
                  @click="CustomerListWithProductDetailsExport"
                  >{{ lang("t_customerListByProduct") }}</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown
                variant="flat-secondary"
                no-caret
                :right="$store.state.appConfig.isRTL"
                toggle-class="px-50 py-50"
                v-if="
                  crm_permissions.includes('management') ||
                  crm_permissions.includes('excel_customer') ||
                  crm_permissions.includes('excel_customer_by_phone')
                "
                boundary="viewport"
                dropleft
              >
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" />
                </template>
                <b-dropdown-item
                  v-if="crm_permissions.includes('management')"
                  @click="modal_assign_agent = true"
                  >{{ lang("t_agentAssign") }}</b-dropdown-item
                >
                <b-dropdown-divider
                  v-if="crm_permissions.includes('management')"
                />

                <b-dropdown-item
                  v-if="crm_permissions.includes('management')"
                  @click="onManagementMenuItemClicked('data_upload')"
                  >{{ lang("t_dataUpload") }}</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="crm_permissions.includes('management')"
                  @click="onManagementMenuItemClicked('data_management')"
                  >{{ lang("t_dataManagement") }}</b-dropdown-item
                >
                <b-dropdown-divider
                  v-if="crm_permissions.includes('management')"
                />
                <b-dropdown-item
                  v-if="crm_permissions.includes('management')"
                  @click="onManagementMenuItemClicked('script_management')"
                  >{{ lang("t_scriptManagement") }}</b-dropdown-item
                >
              </b-dropdown>
            </b-card-text>

            <b-row class="mx-0" style="display: grid">
              <b-table
                style="overflow: auto; max-height: 44rem; max-width: 100%"
                :tbody-tr-class="rowClass"
                :tbody-td-class="rowClass"
                hover
                responsive="lg"
                :items="customer_list"
                :fields="headers.filter((e) => e.visible == true)"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @row-dblclicked="selectCustomer"
                small
                class="position-relative"
              >
                <template #cell(queue)="data">
                  <b-badge variant="light-primary">
                    {{
                      queues.find((e) => e.internal_name == data.value)
                        ? queues.find((e) => e.internal_name == data.value)
                            .display_name
                        : data.value
                    }}
                  </b-badge>
                </template>
                <template #cell(call_direction)="data">
                  <b-icon
                    icon="telephone-inbound"
                    v-if="data.value == 'inbound'"
                  ></b-icon>
                  <b-icon
                    icon="telephone-outbound"
                    v-if="data.value == 'outbound'"
                  ></b-icon>
                  <b-icon
                    icon="telephone-outbound"
                    v-if="data.value == 'dialer'"
                  ></b-icon>
                </template>
                <template #cell(contact_number)="data">
                  <a href="#" @click="start_call(data.value)">
                    {{ GetRemoteNumber(data.value) }}</a
                  >
                </template>
                <template #cell(actions)="data">
                  <b-dropdown
                    boundary="viewport"
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="data.item.note.length > 0"
                      @click="openNote(data.item.note)"
                    >
                      <b-icon icon="card-text"></b-icon>
                      <span class="align-middle ml-50">{{
                        lang("t_note")
                      }}</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="data.item.RecordingFile != ''"
                      @click="openAudio(data.item)"
                    >
                      <b-icon icon="play-fill"></b-icon>
                      <span class="align-middle ml-50">{{
                        lang("t_recordingFile")
                      }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
                <template #cell(rate)="data">
                  <b-progress
                    show-value
                    :value="(data.value * 100) / extra.total_finish_code_counts"
                    :max="100"
                    :variant="
                      parseInt(
                        (data.value / extra.total_finish_code_counts) * 100
                      ) > 40
                        ? 'success'
                        : 'warning'
                    "
                  />
                </template>

                <template #cell(category)="data">
                  <center>
                    <b-badge :variant="'light-secondary'">
                      {{ data.value }}
                    </b-badge>
                  </center>
                </template>
              </b-table>
            </b-row>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->

                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="pageOptions"
                  class="mr-1"
                  @input="recordPerPage(perPage)"
                />

                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    totalRows +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
                <b-button
                  v-if="isDataArrayEmpty(filter_db)"
                  @click="clearFilter"
                  v-b-tooltip.hover.top="lang('t_clearFilters')"
                  v-b-tooltip.hover.v-secondary
                  size="sm"
                  class="px-50 py-50 ml-1"
                  variant="flat-secondary"
                >
                  <b-icon icon="trash2"></b-icon>
                </b-button>
              </div>

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @input="getCustomers"
                size="sm"
                class="mb-0"
                align="right"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-tab>
      <b-tab
        :ref="`customer_${customer._id}`"
        lazy
        v-for="(customer, i) in customers"
        :key="i"
      >
        <template #title>
          <div @contextmenu.prevent="$refs.menu.open">
            <span class="text-truncate" style="max-width: 10rem">{{
              customer[
                headers.find((e) => e.title == true)
                  ? headers.find((e) => e.title == true).key
                  : "customer_name"
              ]
            }}</span>
            <b-button
              @click="closeTab(customer)"
              size="sm"
              class="btn btn-icon px-0 py-0 ml-1"
              variant="flat-secondary"
            >
              <feather-icon class="px-0 mx-0" icon="XIcon"></feather-icon>
            </b-button>
          </div>
        </template>
        <customer-details
          :selected_customer="customer"
          :sms_templates="sms_templates"
          :sms_settings="sms_settings"
          :wp_providers="wp_providers"
          :script_tmp="script_tmp"
          :script="script"
          :headers="headers"
        ></customer-details>
      </b-tab>
      <!-- context -->
      <vue-context ref="menu">
        <li>
          <b-link
            @click="customers = []"
            v-for="data in menuData"
            :key="data.text"
            class="d-flex align-items-center"
          >
            <feather-icon :icon="data.icon" size="16" />
            <span class="ml-75">{{ data.text }}</span>
          </b-link>
        </li>
      </vue-context>
    </b-tabs>

    <!-- SET COLUMNS -->
    <b-modal
      @ok="SaveColumns"
      centered
      :title="lang('t_columnVisibilityOptions')"
      v-model="modal_column_settings"
    >
      <div style="height: 70vh; overflow-y: auto">
        <b-row class="mx-0" :key="i" v-for="(col, i) in headers">
          <b-col cols="10"> {{ col.label }} {{ lang("t_showHide") }} </b-col>
          <b-col cols="2">
            <b-form-checkbox
              class="mb-50"
              v-model="col.visible"
            ></b-form-checkbox>
            <!-- <span style="font-size: 14px; font-weight: bold" class="mr-1">
              <b-button
                :variant="col.visible ? 'flat-success' : 'flat-danger'"
                class="btn-icon rounded-circle"
                @click="
                  col.visible = !col.visible;
                  !col.visible ? (TableFilters[col.value] = []) : null;
                "
              >
                <feather-icon
                  class="badge-glow"
                  size="18"
                  :icon="col.visible ? 'EyeIcon' : 'EyeOffIcon'"
                />
              </b-button>
            </span> -->
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <!-- DYNAMIC MODAL -->
    <b-modal
      centered
      :title="dynamic_modal_title"
      hide-footer
      v-model="dynamic_modal"
      body-class="px-0 py-0"
      :dialog-class="dynamic_modal_name == 'script_management' ? 'dClass' : ''"
    >
      <data-upload
        v-if="dynamic_modal_name == 'data_upload'"
        :title="dynamic_modal_title"
      ></data-upload>
      <script-management
        v-if="dynamic_modal_name == 'script_management'"
        :title="dynamic_modal_title"
      ></script-management>
      <vspr
        v-if="dynamic_modal_name == 'data_management'"
        :title="dynamic_modal_title"
      ></vspr>
    </b-modal>

    <!-- ADD CUSTOMER -->
    <b-modal
      no-stacking
      centered
      :title="lang('t_addCustomer')"
      size="lg"
      v-model="modal_add_customer"
      @ok="addNewCustomer"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
    >
      <b-row>
        <b-col>
          <b-form-group label-for="input-invalid">
            <b-form-input
              v-if="new_customer_info && new_customer_info.phones.length > 0"
              v-model="new_customer_info.phones[0].Telefon"
              placeholder="Telefon *"
            />
            <b-form-invalid-feedback> Zorunlu alan </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <v-select
              v-model="new_customer_info['queue']"
              :options="queues"
              label="display_name"
              value="internal_name"
              :placeholder="lang('t_queue') + ' *'"
              :reduce="(val) => val.internal_name"
            ></v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="6"
          v-for="(col, i) in columns.filter((e) => !voyce_columns.includes(e))"
          :key="i"
        >
          <b-form-input
            class="mb-1"
            v-model="new_customer_info[col]"
            :placeholder="col"
          >
          </b-form-input>
        </b-col>
      </b-row>
    </b-modal>

    <!-- ASSIGN AGENT -->
    <b-modal
      @ok="assignAgent"
      centered
      :title="lang('t_agentAssign')"
      v-model="modal_assign_agent"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
    >
      <b-form-group :label="lang('t_assigmnentType')" label-for="assign">
        <v-select
          @input="onChangeSelectedAssignType"
          v-model="selected_assign_type"
          :options="selected_assign_type_options"
          label="display_name"
          :placeholder="lang('t_assigmnentType')"
          :reduce="(val) => val"
        ></v-select>
      </b-form-group>
      <b-form-group label-for="assign_agents">
        <v-select
          :closeOnSelect="false"
          multiple
          v-model="selected_assign_users"
          :options="agents"
          label="short_username"
          :placeholder="lang('t_agents')"
          :reduce="(val) => val.username"
        ></v-select>
      </b-form-group>
      <div class="mb-2">
        <b-form-group
          v-if="selected_assign_type.internal_name == 'shuffle'"
          :label="''"
          label-for="assign_agents"
        >
          <b-form-checkbox
            v-model="assign_for_product_agent"
            class="custom-control-primary"
            name="check-button"
          >
            <span> {{ lang("t_assignForProductAgent") }}</span>
          </b-form-checkbox>
        </b-form-group>
        <b-form-group v-if="assign_for_product_agent" label-for="assign_agents">
          <v-select
            v-model="selected_assign_product_fields"
            :options="headers"
            label="label"
            :placeholder="lang('t_columns')"
            :reduce="(val) => val.key"
          ></v-select>
        </b-form-group>
      </div>
    </b-modal>

    <!-- FILTER -->
    <b-modal
      size="lg"
      centered
      :title="lang('t_filters')"
      v-model="modal_filters"
      :cancel-title="lang('t_clearFilters')"
      @cancel="clearFilter"
      :ok-title="lang('t_ok')"
    >
      <b-row>
        <b-col
          :key="col.key"
          v-for="col in headers.filter(
            (e) =>
              ['create_date', 'insert_date', 'action_date'].includes(e.key) &&
              e.visible == true
          )"
          sm="12"
          md="6"
          lg="6"
        >
          <flat-pickr
            v-model="filter_db[col.key].data"
            :placeholder="`${col.label}`"
            class="form-control mt-1"
            :config="{ mode: 'range' }"
            @on-change="filterChanged"
          />
        </b-col>
        <b-col
          :key="col.key"
          v-for="col in headers.filter(
            (e) =>
              [
                'finish_code',
                'status',
                'attempts',
                'total_attempts',
                'queue',
              ].includes(e.key) && e.visible == true
          )"
          sm="12"
          md="6"
          lg="6"
        >
          <v-select
            class="mt-1"
            v-model="filter_db[col.key].data"
            :options="filter_db[col.key].items"
            multiple
            :filterable="false"
            :placeholder="`${col.label}`"
            @input="filterChanged"
          >
            <template #list-header>
              <div class="my-50 mx-50">
                <b-button
                  class="w-50"
                  size="sm"
                  variant="flat-secondary"
                  @click="
                    () => {
                      filter_db[col.key].data = filter_db[col.key].items;
                      filterChanged();
                    }
                  "
                >
                  <feather-icon size="14" icon="CheckCircleIcon" />
                  {{ lang("t_selectAll") }}
                </b-button>
                <b-button
                  class="w-50"
                  size="sm"
                  variant="flat-secondary"
                  @click="
                    () => {
                      filter_db[col.key].data = [];
                    }
                  "
                >
                  <feather-icon size="14" icon="XOctagonIcon" />
                  {{ lang("t_clear") }}
                </b-button>
              </div>
            </template>
          </v-select>
        </b-col>
        <b-col
          :key="col.key"
          v-for="col in headers.filter(
            (e) =>
              ![
                'status',
                'finish_code',
                'queue',
                'create_date',
                'create_time',
                'insert_date',
                'action_date',
                'attempts',
                'total_attempts',
              ].includes(e.key) && e.visible == true
          )"
          sm="12"
          md="6"
          lg="6"
        >
          <v-select
            class="mt-1"
            v-model="filter_db[col.key].data"
            :options="filter_db[col.key].items"
            multiple
            :filterable="false"
            :placeholder="`${col.label} ${lang(
              't_pleaseWriteSomethingToSeeYourOptions'
            )}`"
            @input="filterChanged"
            @search="
              (search, loading) => {
                loading(true);
                filterSearch(search, col.key).then(() => {
                  loading(false);
                });
              }
            "
          ></v-select>
        </b-col>
      </b-row>

      <template #modal-footer>
        <div class="w-100 d-flex align-items-center">
          <b-button
            @click="clearFilter"
            v-b-tooltip.hover.top="lang('t_clearFilters')"
            v-b-tooltip.hover.v-primary
            variant="flat-primary"
            class="py-50 px-50"
          >
            <i class="bi bi-arrow-counterclockwise font-medium-5"></i>
          </b-button>
          <b-button
            class="ml-auto"
            variant="secondary"
            @click="modal_filters = false"
          >
            {{ lang("t_cancel") }}
          </b-button>
          <b-button
            class="ml-1"
            variant="primary"
            @click="modal_filters = false"
          >
            {{ lang("t_ok") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>


<script>
import {
  BFormInvalidFeedback,
  BCardFooter,
  BContainer,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  VBTooltip,
  BFormSelect,
  BInputGroupAppend,
  BBadge,
  BTable,
  BPagination,
  BInputGroup,
  BListGroupItem,
  BListGroup,
  BFormCheckbox,
  BTabs,
  BTab,
  BModal,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BButton,
  BFormRadio,
  BInputGroupPrepend,
  BDropdownForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import DataUpload from "./DataUpload.vue";
import ScriptManagement from "./ScriptManagement.vue";
import vspr from "./VSPR.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { $themeColors } from "@themeConfig";
import Cleave from "vue-cleave-component";
import CustomerDetails from './CustomerDetails.vue';
import VueContext from 'vue-context';

export default {

  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      menuData: [

        { icon: 'XIcon', text: 'Tüm Sekmeleri Kapat' },
      ],
      tab_index: 1,
      is_progress: false,
      modal_filters: false,
      modal_column_settings: false,
      modal_add_customer: false,
      modal_assign_agent: false,
      dynamic_modal: false,
      customers: [],
      column_labels: {
        customer_no: globalThis._lang('t_customerNo'),
        customer_name: globalThis._lang('t_customerName'),
        customer_identifier: globalThis._lang('t_customerIdentifier'),
        queue: globalThis._lang('t_queue'),
        active: globalThis._lang('t_state'),
        status: globalThis._lang('t_status'),
        assigned_agent: globalThis._lang('t_agent'),
        attempts: globalThis._lang('t_attempts'),
        total_attempts: globalThis._lang('t_totalAttempts'),
        campaign: globalThis._lang('t_campaign'),
        finish_code: globalThis._lang('t_finishCode'),
        insert_date: globalThis._lang('t_insertDate'),
        action_date: globalThis._lang('t_actionDate'),
        action_time: globalThis._lang('t_actionTime'),
        create_date: globalThis._lang('t_createDate'),
        create_time: globalThis._lang('t_createTime'),
        ex_agent: globalThis._lang('t_exAgent'),
        assign_date: globalThis._lang('t_assignDate'),
        new_assets: globalThis._lang('t_newAssets'),
        reached: globalThis._lang('t_reached'),
        reached_date: globalThis._lang('t_reachedDate'),
      },
      TableFiltersNew: [],
      TableFilters: {},
      call_list_items: [{ internal_name: "call_list", display_name: globalThis._lang("t_callList") }, { internal_name: "pool", display_name: globalThis._lang("t_pool") }],
      selected_call_list: { internal_name: "call_list", display_name: globalThis._lang("t_callList") },
      customer_list: [],
      saved_headers: [],
      headers: [],
      pageOptions: [15, 50, 100, 250, 500],
      totalRows: 0,
      currentPage: 1,
      totalRecords: 0,
      perPage: 15,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      queues: [],
      agents: [],
      crm_permissions: [],
      customerSearchTerm: "",
      customerSortField: globalThis.env.CRM_SORT_COLUMN ?? '',
      customerSortBy: "asc",
      filter_db: {
        status: {
          data: [],
          loading: false,
          items: [],
        },
        ["queue"]: {
          data: [],
          loading: false,
          items: [],
        },
        finish_code: {
          data: [],
          loading: false,
          items: [],
        },
        total_attempts: {
          data: [],
          loading: false,
          items: [],
        },
        attempts: {
          data: [],
          loading: false,
          items: [],
        },
      },
      sms_templates: [],
      sms_settings: [],
      wp_providers: [],
      dynamic_modal_name: '',
      dynamic_modal_title: '',
      new_customer_info: {
        ["queue"]: "",
        phones: [{ Telefon: "" }],
      },
      voyce_columns: [
        "queue",
        "customer_identifier_masked",
        "_id",
        "active",
        "assign_date",
        "assigned_agent",
        "ex_agent",
        "cycles",
        "notes",
        "sms",
        "extra_data",
        "files",
        "phones",
        "products",
        "create_date",
        "create_time",
        "action_date",
        "action_time",
        "finish_code",
        "campaign",
        "status",
        "attempts",
        "insert_date",
        "total_attempts",
        "assigned_agent2",
      ],
      columns: [],
      selected_assign_users: [],
      selected_assign_type: {
        internal_name: "shuffle",
        display_name: `${globalThis._lang("t_shuffle")}`,
      },
      selected_assign_type_options: [
        {
          internal_name: "pool",
          display_name: `${globalThis._lang("t_contactPool")}`,
        },
        {
          internal_name: "shuffle",
          display_name: `${globalThis._lang("t_shuffle")}`,
        },
      ],
      assign_for_product_agent: false,
      selected_assign_product_fields: '',
      filterSearchInterval: null,
      script_tmp: '',
      script: '',
    };
  },
  components: {
    BDropdownForm,
    BFormInvalidFeedback,
    CustomerDetails,
    BCardFooter,
    BInputGroupPrepend,
    BSpinner,
    BFormRadio,
    BContainer,
    Cleave,
    BFormDatepicker,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardActions,
    DataUpload,
    ScriptManagement,
    vspr,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormSelect,
    BInputGroupAppend,
    BBadge,
    BTable,
    BPagination,
    BInputGroup,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormInput,
    BTabs,
    BTab,
    BModal,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
    flatPickr,
    vSelect,
    VueContext,

  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    setTitle(col) {
      this.headers = this.headers.map(i => {
        return { ...i, title: false };
      });

      col.title = !col.title;

      this.headers = this.headers.map(i => {
        if (i.label === col.label) {
          return col;
        }
        return i;
      });
    },
    setSubTitle(col) {
      this.headers = this.headers.map(i => {
        return { ...i, sub_title: false };
      });

      col.sub_title = !col.sub_title;

      this.headers = this.headers.map(i => {
        if (i.label === col.label) {
          return col;
        }
        return i;
      });
    },

    async next_customer() {
      this.customers = [];
      await this.getCustomersNext();
      // this.customer_list = this.customer_list.filter(
      //   (e) => e["customer_no"] != this.selected_customer["customer_no"]
      // );
      await this.selectCustomer(this.customer_list[0]);
    },

    getCustomersNext: async function () {
      this.is_progress = true;
      let page_page = this.get_customer_page;

      this.insert_date_modal = false;
      this.action_date_modal = false;
      this.create_date_modal = false;

      let all_filters = {};
      if (this.headers.length > 0) {
        let date_format = {};
        if (this.filter_db.insert_date.data.length > 0) {
          date_format["insert_date"] = this.filter_db.insert_date.data.includes(
            " to "
          )
            ? this.filter_db.insert_date.data.split(" to ").join("|")
            : this.filter_db.insert_date.data +
            "|" +
            this.filter_db.insert_date.data;
        }
        if (this.filter_db.create_date.data.length > 0) {
          date_format["create_date"] = this.filter_db.create_date.data.includes(
            " to "
          )
            ? this.filter_db.create_date.data.split(" to ").join("|")
            : this.filter_db.create_date.data +
            "|" +
            this.filter_db.create_date.data;
        }
        if (this.filter_db.action_date.data.length > 0) {
          date_format["action_date"] = this.filter_db.action_date.data.includes(
            " to "
          )
            ? this.filter_db.action_date.data.split(" to ").join("|")
            : this.filter_db.action_date.data +
            "|" +
            this.filter_db.action_date.data;
        }
        let date_format2 = {};
        if (this.filter_db.insert_date.data.length > 0) {
          date_format2["insert_date"] =
            this.filter_db.insert_date.data.includes(" to ")
              ? this.filter_db.insert_date.data.split(" to ").join(",")
              : this.filter_db.insert_date.data +
              "," +
              this.filter_db.insert_date.data;
        }
        if (this.filter_db.create_date.data.length > 0) {
          date_format2["create_date"] =
            this.filter_db.create_date.data.includes(" to ")
              ? this.filter_db.create_date.data.split(" to ").join(",")
              : this.filter_db.create_date.data +
              "," +
              this.filter_db.create_date.data;
        }
        if (this.filter_db.action_date.data.length > 0) {
          date_format2["action_date"] =
            this.filter_db.action_date.data.includes(" to ")
              ? this.filter_db.action_date.data.split(" to ").join(",")
              : this.filter_db.action_date.data +
              "," +
              this.filter_db.action_date.data;
        }
        console.log("date_format", date_format);

        console.log("geldi");
        all_filters = {};
        for (const col of this.headers) {
          if (["insert_date", "action_date", "create_date"].includes(col.key)) {
            all_filters[col.key] = date_format[col.key];
          } else {
            all_filters[col.key] =
              this.filter_db[col.key].data.length > 0
                ? this.filter_db[col.key].data.join("|")
                : "";
          }
        }


        // this.TableFiltersNew = [{}];
        // for (const col of this.headers) {
        //   if (["insert_date", "action_date", "create_date"].includes(col.key)) {
        //     this.TableFiltersNew[0][col.key] = date_format2[col.key];
        //   } else {
        //     this.TableFiltersNew[0][col.key] = {
        //       ...(this.filter_db[col.key].data.length > 0 && {
        //         [col.key]:
        //           this.filter_db[col.key].data.length > 0
        //             ? this.filter_db[col.key].data.join(",")
        //             : "",
        //       }),
        //     };
        //   }
        // }
      }

      var response = (
        await this.$http_in.post(`crm/v1/Customers`, {
          collection: `${globalThis.user.selected_project}_customers`,
          list_type: this.selected_call_list.internal_name,
          display_passive_records: (this.selected_call_list.internal_name == 'all' && this.crm_permissions.includes("display_passive_records")),
          page: this.currentPage,
          searchTerm: this.customerSearchTerm,
          sort: globalThis.env.CRM_SORT_COLUMN ?? '',
          sortBy: this.customerSortBy,
          limit: this.perPage,
          filters: JSON.stringify(all_filters),
        })
      ).data;
      console.log("response", response);
      this.totalRows = response.count;
      this.headers = [];
      this.TableFilters = {};

      for (const row of response.result) {
        row["insert_date"] = [null, undefined, "-1"].includes(
          row["insert_date"]
        )
          ? " "
          : row["insert_date"].substr(0, 10);
        row["action_date"] = [null, undefined, "-1"].includes(
          row["action_date"]
        )
          ? " "
          : row["action_date"].substr(0, 10);
        row["create_date"] = [null, undefined, "-1"].includes(
          row["create_date"]
        )
          ? " "
          : row["create_date"].substr(0, 10);
        for (const key of Object.keys(row)) {
          // if (!['insert_date', 'action_date'].includes(key)) {
          row[key] = [null, undefined].includes(row[key]) ? " " : row[key];
          // }
        }
        // console.log(row);
      }
      this.customer_list = [...response.result];

      this.filtered_customer_count = this.customer_list.length;
      for (const item of this.columns) {
        if (
          [
            "_id",
            "campaign",
            "assign_agent",
            "phones",
            "products",
            "notes",
            "active",
            "cycles",
            "extra_data",
            "files",

            "sms",
            "files",
            "customer_identifier_masked",
          ].includes(item)
        ) {
          continue;
        }
        this.TableFilters[item] = [];
        // this.EmptyTableFilters[item] = [];

        if (
          !Object.keys(this.filter_db).includes(
            item == "customer_identifier" ? "customer_identifier_masked" : item
          )
        ) {
          this.filter_db[item == "customer_identifier" ? "customer_identifier_masked" : item] = {
            data: [],
            loading: false,
            items: [],
          };
        }

        const savedHeader = this.saved_headers.find((e) => e.key == item) || {};
        this.headers.push({
          label: this.column_labels[item] || item,
          align: "left",
          sortable: true,
          customFilterable: true,
          key: item === "customer_identifier" ? "customer_identifier_masked" : item,
          class: "text-nowrap",
          visible: savedHeader.visible !== undefined ? savedHeader.visible : true,
          title: savedHeader.title || false,
          sub_title: savedHeader.sub_title || false,
        });

        for (const row of this.customer_list) {
          for (const column of this.headers) {
            // if (column.key != "finish_code") {
            row[column.key] =
              typeof row[column.key] == "string"
                ? row[column.key].split("-").join(".").replace(/ /g, "\u00a0")
                : row[column.key];
            // }
          }
        }
        console.log("headers", this.headers);

        console.log("this.filter_db", this.filter_db);

        this.headers = this.headers.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
      }
      console.log("headers", this.headers);
      this.TableFilters = { ...this.TableFilters };
      // this.EmptyTableFilters = { ...this.EmptyTableFilters };
      this.is_progress = false;
    },

    handleKeydown(event) {
      console.log("event", event);
      if (event.altKey) {
        return;
      }

      if (["INPUT", "TEXTAREA", "SELECT"].includes(document.activeElement.tagName)) {
        return;
      }

      if (event.shiftKey && event.key === 'ArrowRight') {
        console.log("test")
        this.next_customer();
      }
    },
    refreshCustomerDetails() {
      try {
        console.log("this.tab_index", this.tab_index, this.$refs[`customer_${this.tab_index}`]);
        if (this.tab_index == 0) { return; }

        let customerRef = this.$refs[`customer_${this.customers[this.tab_index - 1]._id}`];
        console.log("customerRef", customerRef);
        if (customerRef) {
          customerRef[0].$children[0].getCustomerDetails();
        } else {
          console.error('Ref hazır değil veya getCustomerDetails metodu tanımlı değil.');
        }
      } catch (error) {
        console.error(error.message);

      }



    },
    async onTabChanged(newTabIndex) {
      if (newTabIndex == 0) {
        this.getCustomers();
      }
    },
    getCustomerContactID: async function (_id) {
      console.log("getCustomerContactID", _id);
      var response = (
        await this.$http_in.get(`/crm/v1/CustomerByContactID/${_id}`)
      ).data;
      console.log("1", response.result);

      if (response.result.length > 0) {
        this.is_progress = true;
        // this.customer_list = [...response.result];
        this.headers = [];

        for (const item of this.columns) {
          if (
            [
              "_id",
              "campaign",
              "assign_agent",
              "phones",
              "products",
              "notes",
              "active",
              "cycles",
              "extra_data",
              "sms",
              "files",
              "customer_identifier_masked",
            ].includes(item)
          ) {
            continue;
          }
          this.TableFilters[item] = [];

          if (
            !Object.keys(this.filter_db).includes(
              item == "customer_identifier" ? "customer_identifier_masked" : item
            )
          ) {
            this.filter_db[item == "customer_identifier" ? "customer_identifier_masked" : item] = {
              data: [],
              loading: false,
              items: [],
            };
          }

          const savedHeader = this.saved_headers.find((e) => e.key == item) || {};
          this.headers.push({
            label: this.column_labels[item] || item,
            align: "left",
            sortable: true,
            customFilterable: true,
            key: item === "customer_identifier" ? "customer_identifier_masked" : item,
            class: "text-nowrap",
            visible: savedHeader.visible !== undefined ? savedHeader.visible : true,
            title: savedHeader.title || false,
            sub_title: savedHeader.sub_title || false,
          });

          this.headers = this.headers.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
        }
        if (globalThis.LayoutContentRendererDefault.current_call_info) {
          globalThis.LayoutContentRendererDefault.current_call_info.customer_no =
            response.result[0]["customer_no"];
        }
        // console.log("selectCustomer", this.customer_list[0]);
        console.log("2", response.result[0]);
        // await this.selectCustomer(response.result[0]);

        this.customers.push(JSON.parse(JSON.stringify(response.result[0])));

        setTimeout(() => {
          if (globalThis.crm.tab_index == 0) {
            globalThis.crm.tab_index = globalThis.crm.customers.length;
            localStorage.setItem("crmData", JSON.stringify(globalThis.crm.$data));
          }


        }, 400);
      }
    },
    getCustomerByPhone: async function (phone) {
      console.log("getCustomerByPhone", phone);
      var response = (
        await this.$http_in.get(`/crm/v1/CustomersByPhone/${phone}`)
      ).data;
      console.log("1", response.result);

      if (response.result.length > 0) {
        this.is_progress = true;
        // this.customer_list = [...response.result];
        this.headers = [];

        for (const item of this.columns) {
          if (
            [
              "_id",
              "campaign",
              "assign_agent",
              "phones",
              "products",
              "notes",
              "active",
              "cycles",
              "extra_data",
              "sms",
              "files",
              "customer_identifier_masked",
            ].includes(item)
          ) {
            continue;
          }
          this.TableFilters[item] = [];

          if (
            !Object.keys(this.filter_db).includes(
              item == "customer_identifier" ? "customer_identifier_masked" : item
            )
          ) {
            this.filter_db[item == "customer_identifier" ? "customer_identifier_masked" : item] = {
              data: [],
              loading: false,
              items: [],
            };
          }

          const savedHeader = this.saved_headers.find((e) => e.key == item) || {};
          this.headers.push({
            label: this.column_labels[item] || item,
            align: "left",
            sortable: true,
            customFilterable: true,
            key: item === "customer_identifier" ? "customer_identifier_masked" : item,
            class: "text-nowrap",
            visible: savedHeader.visible !== undefined ? savedHeader.visible : true,
            title: savedHeader.title || false,
            sub_title: savedHeader.sub_title || false,
          });

          this.headers = this.headers.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
        }
        if (globalThis.LayoutContentRendererDefault.current_call_info) {
          globalThis.LayoutContentRendererDefault.current_call_info.customer_no =
            response.result[0]["customer_no"];
        }
        // console.log("selectCustomer", this.customer_list[0]);
        console.log("2", response.result[0]);
        // await this.selectCustomer(response.result[0]);

        this.customers.push(JSON.parse(JSON.stringify(response.result[0])));

        setTimeout(() => {
          if (globalThis.crm.tab_index == 0) {
            globalThis.crm.tab_index = globalThis.crm.customers.length;
            localStorage.setItem("crmData", JSON.stringify(globalThis.crm.$data));
          }


        }, 400);
      } else {
        if (this.crm_permissions.includes("add_customer")) {
          this.tab_index = 0;
          this.modal_add_customer = true;
          this.resetCustomerInfo();
          this.new_customer_info.phones[0].Telefon =
            globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.substr(
              -10
            ) || "";
          localStorage.setItem("crmData", JSON.stringify(globalThis.crm.$data));

        }
      }
    },
    isDataArrayEmpty(filter_db) {
      for (const item of Object.keys(filter_db)) {
        if (['create_date', 'insert_date', 'action_date'].includes(item)) {
          if (filter_db[item].data && filter_db[item].data.length > 0) {
            return true;
          }
        }
        if (filter_db[item].data && Array.isArray(filter_db[item].data) && filter_db[item].data.length > 0) {
          return true;
        }
      }
      return false;
    },
    clearFilter() {
      for (const item of Object.keys(this.filter_db)) {
        this.filter_db[item].data = [];
      }
      this.filter_db = { ...this.filter_db };

      this.TableFiltersNew = [{}];
      this.getCustomers();
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    async getAllByField(field) {
      if (this.filter_db[field] && this.filter_db[field].items && this.filter_db[field].items.length > 0) {
        return;
      }
      var response = (
        await this.$http_in.post(`crm/v1/GetAllFilterByField`, { field })
      ).data;
      this.filter_db[field].items = [];
      let items = [];
      for (const item of this.sortByKey(response.result, "_id")) {
        items.push(item._id);
      }
      this.filter_db[field].items = items;
    },

    filterSearch: async function (searchTerm, col) {
      clearTimeout(this.filterSearchInterval);

      this.filterSearchInterval = setTimeout(async () => {
        this.filter_db[col].loading = true;
        var response = (
          await this.$http_in.post(`crm/v1/FilterSearch`, {
            searchTerm,
            col,
          })
        ).data;

        let items = [];
        for (const item of response.result) {
          items.push(item._id);
        }
        this.filter_db[col].items = [...items];
        this.filter_db[col].loading = false;
        this.filter_db = { ...this.filter_db };
      }, 1000);
    },
    filterChanged() {
      this.customerSearchTerm = '';
      this.currentPage = 1;
      this.getCustomers();
    },
    onChangeSelectedAssignType() {
      if (this.selected_assign_type.internal_name == 'pool') {
        this.assign_for_product_agent = false;
        this.selected_assign_product_fields = '';
      }
    },
    async assignAgent() {
      if (this.selected_assign_users.length == 0) {
        this.$swal({
          title: globalThis._lang('t_warning'),
          text: globalThis._lang('t_pleaseDoNotLeaveAnyBlankFields'),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return;
      }

      if (this.selected_assign_type.internal_name == "pool") {
        this.$swal({
          title: globalThis._lang("t_areYouSure"),
          text: globalThis._lang(
            "t_allUnassignedCasesWillBeAssignedToSelectedAgentsAreYouSure"
          ),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.lang("t_yes"),
          cancelButtonText: this.lang("t_cancel"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          console.log("res", result);
          if (result.value) {
            this.poolAssign();
          }
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_areYouSure"),
          text: `${this.totalRows} ${globalThis._lang(
            "t_amountOfCasesWillBeUpdatedAreYouSure"
          )}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.lang("t_yes"),
          cancelButtonText: this.lang("t_cancel"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          console.log("res", result);
          if (result.value) {
            this.shuffle();
          }
        });
      }
    },
    shuffle: async function () {
      let all_filters = this.createFilterObject();

      this.is_progress = true;
      await this.$http_in.post("crm/v1/ShuffleAssignedAgents", {
        filters: all_filters,
        agents: this.selected_assign_users,
        assing_product: this.assign_for_product_agent,
        field: this.selected_assign_product_fields,
      });
      this.is_progress = false;
      this.getCustomers();
      this.selected_assign_users = [];
    },
    poolAssign: async function () {
      var response = (
        await this.$http_in.post(`crm/v1/SetAssignAgent`, {
          data: this.selected_assign_users,
          assing_product: this.assign_for_product_agent,
          field: this.selected_assign_product_fields,
        })

      ).data;

      var success_count = response.success_count;
      var failed_count = response.failed_count;
      if (failed_count > 0) {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: `${failed_count} ${globalThis._lang(
            "t_casesCouldNotBeAssigned"
          )}`,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.getCustomers();
        this.$swal({
          icon: "success",
          title: globalThis._lang("t_processSuccess"),
          text: `${success_count} ${globalThis._lang(
            "t_customersHaveSuccessfullyBeenAssigned"
          )}`,
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
      this.selected_assign_users = [];
    },

    async addNewCustomer(bvModalEvent) {
      this.new_customer_info["queue"] = this.new_customer_info.queue;
      if (
        [null, undefined, ""].includes(this.new_customer_info["customer_no"])
      ) {
        this.new_customer_info["customer_no"] = new Date().getTime().toString();
      }

      if (
        this.new_customer_info.queue == "" ||
        ["anonymous", "Unknown", ""].includes(
          this.new_customer_info.phones[0].Telefon
        )
      ) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        bvModalEvent.preventDefault();
        return;
      }

      delete this.new_customer_info.queue;
      var response = (
        await this.$http_in.post(`crm/v1/NewCustomer`, {
          new_customer_info: this.new_customer_info,
        })
      ).data;

      if (response) {
        this.selectCustomer({ ...this.new_customer_info, _id: response.insertedId });

      }
    },
    resetCustomerInfo: function () {
      this.new_customer_info = {
        ["queue"]: "",
        phones: [{ Telefon: "" }],
      };

      for (const item of this.columns) {
        if (item == "_id") {
          continue;
        }
        this.new_customer_info[item] = "";
        if (item == "queue") {
          this.new_customer_info["queue"] = "";
        }
        if (item == "finish_code") {
          this.new_customer_info[item] = "-";
        }
        if (item == "phones") {
          this.new_customer_info[item] = [
            {
              Telefon: "",
              status: "C",
              attempts: 0,
              total_attempts: 0,
              Onaylı: false,
            },
          ];
        }
        if (item == "status") {
          this.new_customer_info[item] = "C";
        }
        if (item == "attempts") {
          this.new_customer_info[item] = 0;
        }
        if (item == "active") {
          this.new_customer_info[item] = true;
        }
        if (item == "create_date") {
          this.new_customer_info[item] = new Date().toISOString().split("T")[0];
        }
        if (item == "notes") {
          this.new_customer_info[item] = [];
        }
        if (item == "sms") {
          this.new_customer_info[item] = [];
        }
        if (item == "extra_data") {
          this.new_customer_info[item] = [];
        }
        if (item == "products") {
          this.new_customer_info[item] = [];
        }
      }
    },
    openAddCustomer() {
      this.modal_add_customer = true;
      this.resetCustomerInfo();
      this.new_customer_info.phones[0].Telefon =
        globalThis.LayoutContentRendererDefault.current_call_info != null
          ? globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.substr(
            -10
          )
          : "";
    },
    onManagementMenuItemClicked(item_name) {
      this.dynamic_modal_name = item_name;
      switch (item_name) {
        case "data_upload":
          this.dynamic_modal_title = globalThis._lang("t_dataUpload");
          break;
        case "data_management":
          this.dynamic_modal_title = globalThis._lang("t_dataManagement");
          break;
        case "script_management":
          this.dynamic_modal_title = globalThis._lang("t_scriptManagement");
          break;

        default:
          break;
      }
      this.dynamic_modal = true;
    },
    getUsers: async function () {
      if (this.agents.length > 0) {
        return;
      }
      var response = (await this.$http_in.get(`crm/v1/Users`)).data;

      this.agents = response;
    },
    getWpProviders: async function () {
      if (this.wp_providers.length > 0) {
        return;
      }
      var response = (
        await this.$http_in.get(`text-channel-wp/v1/WhatsappProvider`)
      ).data;
      this.wp_providers = response.filter(e => globalThis.user.selected_queues.includes(e.queue));
    },
    getSmsSettings: async function () {
      if (this.sms_settings.length > 0) {
        return;
      }
      var response = (await this.$http_in.get(`crm/v1/SmsSettings`)).data;

      this.sms_settings = response;
    },
    getSmsTemplate: async function () {
      if (this.sms_templates.length > 0) {
        return;
      }
      var response = (await this.$http_in.get(`/spv/v1/SmsTemplate`)).data;
      this.sms_templates = response;
    },
    getScript: async function () {
      try {
        if (this.script && this.script.length > 0) {
          return;
        }
        var response = (await this.$http_in.get(`/crm/v1/Script`)).data;

        this.script_tmp = response[0].content || "";
        this.script = response[0].content || "";
      } catch (error) {
        this.script_tmp = "";
        this.script = "";
      }
    },
    createFilterObject() {
      let all_filters = {};
      if (this.headers.length > 0) {
        let date_format = {};
        if (this.filter_db.insert_date.data.length > 0) {
          date_format["insert_date"] = this.filter_db.insert_date.data.includes(
            " to "
          )
            ? this.filter_db.insert_date.data.split(" to ").join("|")
            : this.filter_db.insert_date.data +
            "|" +
            this.filter_db.insert_date.data;
        }
        if (this.filter_db.create_date.data.length > 0) {
          date_format["create_date"] = this.filter_db.create_date.data.includes(
            " to "
          )
            ? this.filter_db.create_date.data.split(" to ").join("|")
            : this.filter_db.create_date.data +
            "|" +
            this.filter_db.create_date.data;
        }
        if (this.filter_db.action_date.data.length > 0) {
          date_format["action_date"] = this.filter_db.action_date.data.includes(
            " to "
          )
            ? this.filter_db.action_date.data.split(" to ").join("|")
            : this.filter_db.action_date.data +
            "|" +
            this.filter_db.action_date.data;
        }
        let date_format2 = {};
        if (this.filter_db.insert_date.data.length > 0) {
          date_format2["insert_date"] =
            this.filter_db.insert_date.data.includes(" to ")
              ? this.filter_db.insert_date.data.split(" to ").join(",")
              : this.filter_db.insert_date.data +
              "," +
              this.filter_db.insert_date.data;
        }
        if (this.filter_db.create_date.data.length > 0) {
          date_format2["create_date"] =
            this.filter_db.create_date.data.includes(" to ")
              ? this.filter_db.create_date.data.split(" to ").join(",")
              : this.filter_db.create_date.data +
              "," +
              this.filter_db.create_date.data;
        }
        if (this.filter_db.action_date.data.length > 0) {
          date_format2["action_date"] =
            this.filter_db.action_date.data.includes(" to ")
              ? this.filter_db.action_date.data.split(" to ").join(",")
              : this.filter_db.action_date.data +
              "," +
              this.filter_db.action_date.data;
        }
        console.log("date_format", date_format);

        console.log("geldi");
        all_filters = {};
        for (const col of this.headers) {
          if (["insert_date", "action_date", "create_date"].includes(col.key)) {
            all_filters[col.key] = date_format[col.key];
          } else {
            all_filters[col.key] =
              this.filter_db[col.key].data.length > 0
                ? this.filter_db[col.key].data.join("|")
                : "";
          }
        }
      }

      return all_filters;
    },

    async CustomerListExport() {

      let all_filters = this.createFilterObject();

      this.is_progress = true;


      var rpr = (
        await this.$http_in.post(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'report-worker')}/report/v1/CustomerListExport`, {
          filter: all_filters,
        })
      ).data;
      console.log("rpr", rpr);

      try {
        while (true) {
          // let response = await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`);
          let response = await this.$http_ex.get(`${rpr}`);
          if (response.status == 200) {
            var anchor = document.createElement("a");
            anchor.href = `${rpr}`;
            anchor.download = `${rpr}`;
            // anchor.href = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
            // anchor.download = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
            anchor.target = "_blank";
            document.body.appendChild(anchor);
            anchor.click();
            this.is_progress = false;
            break;
          }
        }
      } catch (error) { }

      // this.is_progress = false;
      // if (![undefined, null, ""].includes(rpr)) {
      //   globalThis.window.location.href = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
      // }
    },
    async CustomerListWithPhoneDetailsExport() {
      let all_filters = this.createFilterObject();

      this.is_progress = true;
      var rpr = (
        await this.$http_in.post(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'report-worker')}/report/v1/DumpCustomersTable`, {
          filter: all_filters,
        })
      ).data;
      console.log("rpr", rpr);
      try {
        while (true) {
          // let response = await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`);
          let response = await this.$http_ex.get(`${rpr}`);
          if (response.status == 200) {
            var anchor = document.createElement("a");
            // anchor.href = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
            anchor.href = `${rpr}`;
            anchor.download = `${rpr}`;
            // anchor.download = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
            anchor.target = "_blank";
            document.body.appendChild(anchor);
            anchor.click();
            this.is_progress = false;
            break;
          }
        }
      } catch (error) { }

      // this.is_progress = false;
      // if (![undefined, null, ""].includes(rpr)) {
      //   globalThis.window.location.href = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
      // }
    },

    async CustomerListWithProductDetailsExport() {
      let all_filters = this.createFilterObject();

      this.is_progress = true;
      var rpr = (
        await this.$http_in.post(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'report-worker')}/report/v1/ProductDetails`, {
          filter: all_filters,
        })
      ).data;
      console.log("rpr", rpr);
      try {
        while (true) {
          // let response = await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`);
          let response = await this.$http_ex.get(`${rpr}`);
          if (response.status == 200) {
            var anchor = document.createElement("a");
            // anchor.href = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
            anchor.href = `${rpr}`;
            anchor.download = `${rpr}`;
            // anchor.download = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
            anchor.target = "_blank";
            document.body.appendChild(anchor);
            anchor.click();
            this.is_progress = false;
            break;
          }
        }
      } catch (error) { }

      // this.is_progress = false;
      // if (![undefined, null, ""].includes(rpr)) {
      //   globalThis.window.location.href = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
      // }
    },
    async export_csv() {
      var _filter = {};
      if (this.TableFiltersNew.length > 0) {
        for (const item of Object.keys(this.TableFiltersNew[0])) {
          console.log(item);
          if (this.TableFiltersNew[0][item].includes(",")) {
            _filter[item] = { $in: this.TableFiltersNew[0][item].split(",") };
          } else {
            _filter[item] = this.TableFiltersNew[0][item];
          }
        }
      }

      this.is_progress = true;
      var rpr = (
        await this.$http.post(
          "/ExportTtsCsvByProduct",
          {
            collection: `wow_project_proje3_customers`,
            filter: _filter,
          },
          {
            headers: { authorization: globalThis.token },
          }
        )
      ).data;
      console.log("rpr", rpr);
      this.is_progress = false;
      if (![undefined, null, ""].includes(rpr)) {
        globalThis.window.location.href = `http://${globalThis.AppConfig.RESTAPI}/outputs/${rpr}`;
      }
    },
    SaveColumns: async function () {
      console.log("test");
      var response = (
        await this.$http_in.post(`crm/v1/Settings`, {
          visible_headers: this.headers,
        })
      ).data;
      await this.getSavedColumns();
      await this.getCustomerColumns();
      await this.getCustomers();
    },
    getCustomers: async function () {
      this.is_progress = true;

      let all_filters = {};
      if (this.headers.length > 0) {
        let date_format = {};
        if (this.filter_db.insert_date.data.length > 0) {
          date_format["insert_date"] = this.filter_db.insert_date.data.includes(
            " to "
          )
            ? this.filter_db.insert_date.data.split(" to ").join("|")
            : this.filter_db.insert_date.data +
            "|" +
            this.filter_db.insert_date.data;
        }
        if (this.filter_db.create_date.data.length > 0) {
          date_format["create_date"] = this.filter_db.create_date.data.includes(
            " to "
          )
            ? this.filter_db.create_date.data.split(" to ").join("|")
            : this.filter_db.create_date.data +
            "|" +
            this.filter_db.create_date.data;
        }
        if (this.filter_db.action_date.data.length > 0) {
          date_format["action_date"] = this.filter_db.action_date.data.includes(
            " to "
          )
            ? this.filter_db.action_date.data.split(" to ").join("|")
            : this.filter_db.action_date.data +
            "|" +
            this.filter_db.action_date.data;
        }
        let date_format2 = {};
        if (this.filter_db.insert_date.data.length > 0) {
          date_format2["insert_date"] =
            this.filter_db.insert_date.data.includes(" to ")
              ? this.filter_db.insert_date.data.split(" to ").join(",")
              : this.filter_db.insert_date.data +
              "," +
              this.filter_db.insert_date.data;
        }
        if (this.filter_db.create_date.data.length > 0) {
          date_format2["create_date"] =
            this.filter_db.create_date.data.includes(" to ")
              ? this.filter_db.create_date.data.split(" to ").join(",")
              : this.filter_db.create_date.data +
              "," +
              this.filter_db.create_date.data;
        }
        if (this.filter_db.action_date.data.length > 0) {
          date_format2["action_date"] =
            this.filter_db.action_date.data.includes(" to ")
              ? this.filter_db.action_date.data.split(" to ").join(",")
              : this.filter_db.action_date.data +
              "," +
              this.filter_db.action_date.data;
        }
        console.log("date_format", date_format);

        console.log("geldi");
        all_filters = {};
        for (const col of this.headers) {
          if (["insert_date", "action_date", "create_date"].includes(col.key)) {
            all_filters[col.key] = date_format[col.key];
          } else {
            all_filters[col.key] =
              this.filter_db[col.key].data.length > 0
                ? this.filter_db[col.key].data.join("|")
                : "";
          }
        }


        // this.TableFiltersNew = [{}];
        // for (const col of this.headers) {
        //   if (["insert_date", "action_date", "create_date"].includes(col.key)) {
        //     this.TableFiltersNew[0][col.key] = date_format2[col.key];
        //   } else {
        //     this.TableFiltersNew[0][col.key] = {
        //       ...(this.filter_db[col.key].data.length > 0 && {
        //         [col.key]:
        //           this.filter_db[col.key].data.length > 0
        //             ? this.filter_db[col.key].data.join(",")
        //             : "",
        //       }),
        //     };
        //   }
        // }
      }

      var response = (
        await this.$http_in.post(`crm/v1/Customers`, {
          collection: `${globalThis.user.selected_project}_customers`,
          list_type: this.selected_call_list.internal_name,
          display_passive_records: (this.selected_call_list.internal_name == 'all' && this.crm_permissions.includes("display_passive_records")),
          page: this.currentPage,
          searchTerm: this.customerSearchTerm,
          sort: this.customerSortField,
          sortBy: this.customerSortBy,
          limit: this.perPage,
          filters: JSON.stringify(all_filters),
          justShowOwnCustomers: this.crm_permissions.includes('just_show_own_customers')
        })
      ).data;
      console.log("response", response);
      this.totalRows = response.count;
      this.headers = [];

      for (const row of response.result) {
        row["insert_date"] = [null, undefined, "-1"].includes(
          row["insert_date"]
        )
          ? " "
          : row["insert_date"].substr(0, 10);
        row["action_date"] = [null, undefined, "-1"].includes(
          row["action_date"]
        )
          ? " "
          : row["action_date"].substr(0, 10);
        row["create_date"] = [null, undefined, "-1"].includes(
          row["create_date"]
        )
          ? " "
          : row["create_date"].substr(0, 10);
        for (const key of Object.keys(row)) {
          // if (!['insert_date', 'action_date'].includes(key)) {
          row[key] = [null, undefined].includes(row[key]) ? " " : row[key];
          // }
        }
        // console.log(row);
      }
      this.customer_list = [...response.result];

      // this.totalRows = this.customer_list.length;
      for (const item of this.columns) {
        if (
          [
            "_id",
            "campaign",
            "assign_agent",
            "phones",
            "products",
            "notes",
            "active",
            "cycles",
            "extra_data",
            "files",
            "view_moment",
            "sms",
            "customer_identifier_masked",
          ].includes(item)
        ) {
          continue;
        }
        this.TableFilters[item] = [];
        // this.EmptyTableFilters[item] = [];

        if (
          !Object.keys(this.filter_db).includes(
            item == "customer_identifier" ? "customer_identifier_masked" : item
          )
        ) {
          this.filter_db[item == "customer_identifier" ? "customer_identifier_masked" : item] = {
            data: [],
            loading: false,
            items: [],
          };
        }

        const savedHeader = this.saved_headers.find((e) => e.key == item) || {};
        this.headers.push({
          label: this.column_labels[item] || item,
          align: "left",
          sortable: true,
          customFilterable: true,
          key: item === "customer_identifier" ? "customer_identifier_masked" : item,
          class: "text-nowrap",
          visible: savedHeader.visible !== undefined ? savedHeader.visible : true,
          title: savedHeader.title || false,
          sub_title: savedHeader.sub_title || false,
        });

        for (const row of this.customer_list) {
          for (const column of this.headers) {
            // if (column.key != "finish_code") {
            row[column.key] =
              typeof row[column.key] == "string"
                ? row[column.key].split("-").join(".").replace(/ /g, "\u00a0")
                : row[column.key];
            // }
          }
        }

        this.headers = this.headers.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
      }
      this.TableFilters = { ...this.TableFilters };
      // this.EmptyTableFilters = { ...this.EmptyTableFilters };
      this.is_progress = false;
    },

    recordPerPage(item) {
      this.perPage = item;
      this.currentPage = 1;
      this.getCustomers();
    },
    selectCustomer: async function (selected_customer) {

      this.is_progress = true;
      this.customers.push(JSON.parse(JSON.stringify(selected_customer)));
      console.log("this.customers.length", this.customers.length);
      setTimeout(() => {
        globalThis.crm.tab_index = globalThis.crm.customers.length;

      }, 100);
      this.is_selected = true;
    },
    rowClass(item, type) {
      if (item.status === "C" && item.total_attempts == 0) return "";
      if (item.status === "K" && item.total_attempts == 0)
        return "table-danger table-style";
      if (item.status === "K" && item.total_attempts > 0)
        return "table-secondary table-style";
      if (item.status === "C" && item.total_attempts > 0)
        return "table-info table-style";
      if (item.status === "U") return "table-warning table-style";
    },

    closeTab(x) {
      this.customers = [...this.customers.filter((e) => e != x)];
      if (this.customers.length == 0) {
        this.getCustomers();
      } else {
        setTimeout(() => {
          this.refreshCustomerDetails();


        }, 100);
      }
    },
    getSavedColumns: async function () {
      var response = (await this.$http_in.get(`crm/v1/Settings`)).data;
      console.log("column", response);
      this.saved_headers = [...(response.visible_headers || [])];

    },
    getCustomerColumns: async function () {
      var response = (await this.$http_in.get(`crm/v1/CustomerColumns`)).data;
      this.columns = response[0].allkeys.sort();
      this.resetCustomerInfo();

    },
  },
  mounted: async function () {
    globalThis.crm = this;
    window.addEventListener('keydown', this.handleKeydown);

    this.queues = globalThis.queues;
    this.crm_permissions = globalThis.permissions["crm"] ?? [];
    if (this.crm_permissions.includes("display_passive_records") && !this.call_list_items.find(e => e.internal_name == 'passive')) {
      this.call_list_items.push({ internal_name: 'passive', display_name: globalThis._lang("t_passive") });
    }
    if (this.crm_permissions.includes("display_all_records") && !this.call_list_items.find(e => e.internal_name == 'all')) {
      this.call_list_items.push({ internal_name: 'all', display_name: globalThis._lang("t_all") });
      this.selected_call_list = { internal_name: 'all', display_name: globalThis._lang("t_all") };
    }




    if (!localStorage.hasOwnProperty("crmData")) {
      this.crm_permissions = globalThis.permissions["crm"] ?? [];
      this.other_permissions = globalThis.permissions["other"] ?? [];
      this.is_progress = true;

    }

    const this_data = JSON.parse(localStorage.getItem("crmData"));
    console.log("this_data", this_data);
    if (![undefined, null].includes(this_data)) {
      for (let [key, val] of Object.entries(this_data)) {
        this[key] = val;
      }
    }

    await this.getSavedColumns();
    await this.getCustomerColumns();

    await Promise.all([
      this.getScript(),
      this.getSmsTemplate(),
      this.getWpProviders(),
      this.getSmsSettings(),
      this.getUsers(),
      this.getAllByField("finish_code"),
      this.getAllByField("queue"),
      this.getAllByField("status"),
      this.getAllByField("attempts"),
      this.getAllByField("total_attempts"),
    ]);

    if (globalThis.LayoutContentRendererDefault.current_call_info == null) {
      await this.getCustomers();
    }

    this.is_progress = false;
  },
  beforeDestroy() {
    localStorage.setItem("crmData", JSON.stringify(this.$data));
    window.removeEventListener('keydown', this.handleKeydown);

  },
};

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-context.scss";

.table-style {
  color: black !important;
}
// .table-style :hover {
//   color: white !important;
// }

.cell-style {
  color: black;
}

.override-overflow {
  overflow: hidden !important;
}

.dCustomClass {
  width: auto;
  height: 30rem;
  overflow-y: auto;
}
</style>
